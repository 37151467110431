import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzCountrySelectorModalComponent } from './kurz-country-selector-modal.component';
import { I18nModule } from '@spartacus/core';
import { ButtonModule } from '@util/components/button/button.module';
import { ColorThemeModule } from '@util/components/shared/color-theme/color-theme.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { KurzModalWrapperModule } from 'src/app/custom/kurz-components/shared/kurz-modal-wrapper/kurz-modal-wrapper.module';

@NgModule({
  declarations: [
    KurzCountrySelectorModalComponent
  ],
  imports: [
    CommonModule,
    KurzModalWrapperModule,
    ButtonModule,
    ColorThemeModule,
    ActivateElementModule,
    I18nModule,
  ]
})
export class KurzCountrySelectorModalModule { }
