import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzCountrySelectorComponent } from './kurz-country-selector.component';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { KurzCountrySelectorModalModule } from './kurz-country-selector-modal/kurz-country-selector-modal.module';



@NgModule({
  declarations: [
    KurzCountrySelectorComponent
  ],
  imports: [
    CommonModule,
    ActivateElementModule,
    KurzCountrySelectorModalModule
  ],
  exports: [
    KurzCountrySelectorComponent
  ]
})
export class KurzCountrySelectorModule { }
